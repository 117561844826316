.bg-stone-1 {
    background-image: url("../../public/images/stone-Texture.jpg");
    background-size: 100% 100%;
    background-color: rgba(11, 11, 11, 0.685);
    background-blend-mode: color-burn;
    z-index: 1;

}

.nav-img {
    content: url("../../public/images/navImg.png");
}