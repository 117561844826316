.app-bg {
  background-image: url("../../public/images/TextureYellow.jpeg");
  background-size: cover;
  /* background-color: yellow; */
}
.page-line {
  border-color: rgb(255, 221, 0);
  opacity: 100;
}
.page-line-2 {
  border-color: rgb(255, 221, 0);
  opacity: 100;
  border-style: dotted;
  
}

.Text-shadow-1 {
  text-shadow: 4px 4px 9px rgba(0,0,0,0.6);
}
.img-1 {
  box-shadow: 2px -5px 112px -4px rgba(0,0,0,0.75);
-webkit-box-shadow: 2px -5px 112px -4px rgba(0,0,0,0.75);
-moz-box-shadow: 2px -5px 112px -4px rgba(0,0,0,0.75);
}

.box-shadow {
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  
}


.vibrate-1 {
	-webkit-animation: vibrate-1 4s linear infinite both;
	        animation: vibrate-1 4s linear infinite both;
          
}

 @-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

.text-pop-up-bottom {
	-webkit-animation: text-pop-up-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: text-pop-up-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
 @-webkit-keyframes text-pop-up-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #4d4d4d, 0 2px 0 #4d4d4d, 0 3px 0 #4d4d4d, 0 4px 0 #4d4d4d, 0 5px 0 #4d4d4d, 0 6px 0 #4d4d4d, 0 7px 0 #4d4d4d, 0 8px 0 #4d4d4d, 0 9px 0 #4d4d4d, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
